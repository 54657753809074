<template >
    <div id="AddUsr">
       
        <div class="form-content">
            <form @submit.prevent="
                submit(
                 name , lastname, role, email, phone, pass, ini, passc, permisos
                )
              ">
                    <div class="text_option txt1">
                           
                            <h3>Información del Usuario</h3>
                    </div>

                   
                   

                    <div class="grup-form dflx">
                        <div>
                            <label for="name" class="name">Nombre </label>
                            <input v-model="name" type="text" id="name" name="name" pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}"   required class="in1">
                        </div>
                        <div>
                            <label for="lastname" class="lastname">Apellido/s</label>
                            <input v-model="lastname" type="text" id="lastname" name="lastname"  pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}" required class="in1">
                        </div>
                    </div>
                     <div class="grup-form dflx">
                        <div>
                            <label for="ini" class="ini">Iniciales</label>
                            <input v-model="ini" type="text" name="ini" id="ini" pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}"  required class="in1">
                        </div>
                        <div>
                            <label for="role" class="role">Rol </label>
                             <select id="select-role" v-model="role" required name="role">
                            <option>
                                admin
                            </option>
                             <option>
                                recepcionista
                            </option>
                             <option>
                                analista
                            </option>
                              <option v-if="identity.role=='spadmin'">
                                spadmin
                            </option>
                            </select>
                        </div>
                        <div v-if="role === 'spadmin'">
                            <label for="permisos" class="permisos">Permisos</label>
                            <select id="select-role" v-model="permisos" name="permisos" >
                            <option>
                                alimentos
                            </option>
                             <option>
                                clinicos
                            </option>
                             <option>
                                todos
                            </option>
                            </select>
                        </div>
                    </div>
                   
                    <div class="grup-form dflx">
                        <div>
                            <label for="phone" class="phone">Teléfono</label>
                            <input v-model="phone" type="text" id="phone" name="phone" minlength="10" maxlength="10" size="10" pattern="\d*" class="in1">
                        </div>
                        <div>
                            <label for="email" class="email">E-mail</label>
                            <input v-model="email" type="email" id="email"  name="email" required class="in1">
                        </div>
                    </div>

                    <div class="grup-form dflx">
                        <div>
                            <label for="pass" class="pass">Contraseña</label>
                            <input v-model="pass" type="password" id="pass" name="pass"  minlength="8" required class="in5" >
                        </div>
                        <div>
                            <label for="passc" class="passc">Confirmar Contraseña</label>
                            <input v-model="passc" type="password" id="passc" name="passc"  minlength="8"  required class="in5" >
                        </div>
                        <div>
                            <label class="cp mostrarcontra" @click="Mostrar()"> <span v-if="mostrarpass">Mostrar</span> <span v-else>Ocultar</span> contraseña</label>                        
                        </div>
                    </div>



                    
                        <div class="dflx">
                               <p @click="wait()" class="btnRegresar">Regresar</p>
                                  <button class="alta" type="submit">Guardar <span>Usuario</span></button>
                        </div>
                     

                        <div v-if="status !=''" class="status_messages">
                            <div v-if="status =='success'" class="msg msg_success">
                                 <p>{{message}}</p>
                            </div>
                            <div  v-if="status =='error'" class="msg msg_error">
                                <p>{{message}}</p>
                            </div>
                        </div>
            </form>
     
             <div v-if="viewModal" class="modal_view">
           
            <div id="modal_add">
            <p @click="closeModal()" class="close_modal"> &#215;</p>
            <h3 class="title_add">Registro <span>exitoso</span></h3>
           
            <div class="modal_add_btns dflx">
                <p @click="Add()" class="otro">AÑADIR OTRO</p> <p  @click="toEdit()" class="editar">EDITAR</p>  <p  @click="back()" class="inicio">INICIO</p>
            </div>
          </div>
        </div>

        </div>
           
    </div>
</template>
<script>
import {mapActions} from 'vuex';
export default {
    name:'AddUsr',
    data(){
        return{
           status:'',
           message:'',
           name:"" ,
           lastname:"",
           role:"admin",
           ini:"",
           phone:"",
           email:"",
           pass:"",
           passc:"",
        mostrarpass:true,
        permisos:"todos",

           //MODAL

            viewModal:false,



        }
    },
     created(){
    
       this.getIdentity()
   },
    computed:{
         identity() {
           return this.$store.getters['admin/getIdentity'];
        },

    },
    methods:{
         ...mapActions('usuarios', ['setAddedUsr']),
         ...mapActions('usuarios', ['setHistoryOptionUsr']),
          getIdentity: async function (){
          let response =  await this.$store.dispatch("admin/getData");
          return response
        },

          wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },
        submit: async function( name , lastname, role, email, phone, pass, ini, passc, permisos){
                 this.status =''
                 this.message =''
             if(pass === passc){
                  let usuario ={
                "name":name,
                "lastname": lastname,
                "phone": phone,
                "email": email,
                "password": pass,
                "role":role,
                "ini":ini,
                 "permisos": permisos
                  }

                  

                    let result = await this.$store.dispatch("usuarios/addItemUsr",  {option:'user', item: usuario});
                   
                    if(result.status=='error'){
                        this.status='error'
                        this.message= result.message
                    }else{ // success
                         this.setAddedUsr(result.added )
                        this.showModal()
                       
                    } 
               
             }else{
                 this.status ='error'
                 this.message ='Las contraseñas no coinciden.'
             }
             

            
    },


     showModal: function(){
         this.viewModal=true
     },
    closeModal:function(){
        this.viewModal=false;
        this.setAddedUsr('')
        this.setHistoryOptionUsr('Default')
    },
    Add:function(){
        this.status='';
           this.message='';
           this.name="" ;
           this.lastname="";
           this.role="admin";
           this.ini="";
           this.phone="";
           this.email="";
           this.pass="";
           this.passc="";
           this.permisos = "todos";
        this.viewModal=false;
        this.setAddedUsr('');
    },
    toEdit:function(){
        
        
        this.setHistoryOptionUsr('Edit')
         this.viewModal=false;
    },
    back:function(){
        setTimeout(() => this.$router.go(), 200);
    },
     Mostrar: function (){
        var tipo = document.getElementById("pass");
        if(tipo.type == "password"){
            tipo.type = "text";
            this.mostrarpass=false;
        }else{
            tipo.type = "password";
            this.mostrarpass=true;
        }

        var tipo2 = document.getElementById("passc");
        if(tipo2.type == "password"){
            tipo2.type = "text";
        }else{
            tipo2.type = "password";
        }
    }, 


   }
}
</script>
<style scoped>


  
    .text_option h3{
   
    margin-top: 0vw;
    font-size: 1.8229166666666667VW;
    color: var(--color-4);
    font-weight: 300 !important;
    font-style: normal;
  }


    .form-content{
        margin-left: 0.9375VW ;
    }

  button.alta{
      width: 7.916666666666666VW;
      height: 2.2916666666666665VW;
      border: 0px;
      color: white;
      background: #00A175;
      margin-left: 0.625VW;
      margin-top: 2.7083333333333335VW;
  }
  button.alta span{
      font-weight: 600 !important;
  }

  label{
      color: #868686;
      font-size: 0.7291666666666666VW;
      font-weight: 400 !important;
     
  }

  input, select{
      border: 0.052083333333333336VW solid #D3D3D3 ;
       color: #868686;
  }

  select:focus-visible{
      outline: none !important;
  }

  input.in1, select{
      width: 12.812499999999998VW;
  }

  input.in5{
      width: 9.812499999999998VW;
  }



    input.in2{
      width: 28.489583333333336VW;
  }

    input.in3{
      width: 31.927083333333332VW;
  }

    input.in4{
      width: 30.677083333333332VW;
  }

    
    
    label.name{
        width: 2.3958333333333335VW; 
        margin-right: 1.7187500000000002VW;
    }

     label.lastname{
            width: 3.6875VW;
    margin-right: 0.364583VW;
    margin-left: 2.25VW;
    }
    label.email{
         width: 2.3875VW; 
        margin-right: 0.3745833333333333VW;
        margin-left: 3.5VW;
    }

    label.role{
        width: 1.3875VW;
    margin-right: 0.3745833333333333VW;
    margin-left: 4.5VW;
    }

    label.permisos{
        width: 3.3875VW;
    margin-right: .5vw;
    margin-left: 1.5VW;
    }
    label.phone{
        width: 3.0208333333333335VW;
        margin-right: 1.09375VW;
    }

    label.ini{
        width: 3.0208333333333335VW;
        margin-right: 1.09375VW;
    }


    label.pass{
        width: 4.1VW; 
    }
    label.passc{
        width: 7.575VW; 
        margin-left: 1.7vw;
    }

    

    .txt2{
        margin-top: 2.083333333333333VW;
    }

    .txt1{
        margin-bottom: 1.6145833333333335VW;
    }



    .grup-form{
        margin-bottom: 0.6770833333333334VW;
    }


  








</style>
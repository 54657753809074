<template>
<div id="HeaderSec">

        <div class="options">
         
         

            <div  class="div_option" v-if="historyOption=='Usuarios'">
               <!-- <div class="arrow_left">
                    <img src="../assets/Arrow_Left.png" alt="">
                </div>-->
                <div class="io_user">
                    <img src="../assets/Usuarios_g.png" alt="">
                </div>
                <div class="text_option">
                    <h3>Usuarios</h3>
                </div>
            </div>
            <div  class="div_option" v-if="historyOption=='Empresas'">
               
                <div class="text_option">
                    <h3>Alta de Clientes/Empresas</h3>
                </div>
            </div>
            <div  class="div_option" v-if="historyOption=='Servicios'">
               
                <div class="text_option">
                    <h3>Solicitud de Servicios</h3>
                </div>
            </div>
            <div  class="div_option" v-if="historyOption=='Dashboard'">
                 <div class="io_dash">
                    <img src="../assets/Dashboard_G.png" alt="">
                </div>
                <div class="text_option">
                    <h3>Dashboard</h3>
                </div>
            </div>

            <div  class="div_option" v-if="historyOption=='Validaciones'">
                <div v-if="historyOptionVal != 'Resultados' && historyOptionVal != 'PDF'" class="dflx">
                        <div class="io_val">
                            <img src="../assets/Resultados_gris.png" alt="">
                        </div>
                        <div class="text_option">
                            <h3>Validaciones</h3>
                        </div>
                  </div>
                  <div v-else class="dflx">
                      <div class="arrow_left">
                    <img src="../assets/Arrow_Left.png" alt="">
                </div>
                <div class="text_option">
                            <h3>Resultados</h3>
                        </div>
                  </div>
              
                
            </div>
              <div  class="div_option" v-if="historyOption=='Muestras'">
                  <div v-if="historyOptionMtr != 'Resultados'" class="dflx">
                        <div class="io_dash">
                            <img src="../assets/muestras_gris.png" alt="">
                        </div>
                        <div class="text_option">
                            <h3>Muestras</h3>
                        </div>
                  </div>
                  <div v-else class="dflx">
                    <div class="arrow_left">
                    <img src="../assets/Arrow_Left.png" alt="">
                </div>
                <div class="text_option">
                            <h3>Resultados</h3>
                        </div>
                  </div>
               
            </div>
             <div  class="div_option" v-if="historyOption=='Parametros'">
               
                <div class="text_option">
                    <h3>Parametros</h3>
                </div>
            </div>
             <div  class="div_option" v-if="historyOption=='Perfiles'">
               
                <div class="text_option">
                    <h3>Perfiles</h3>
                </div>
            </div>
             <div  class="div_option" v-if="historyOption=='Solicitudes'">
               
                <div v-if="historyOptionSrv == 'Default'" class="text_option">
                    <h3>Solicitudes</h3>
                </div>
                <div v-else class="text_option">
                     <h3>Información Solicitud</h3>
                </div>
            </div>

             <div  class="div_option" v-if="historyOption=='Clientes'">
               
                <div class="text_option">
                    <h3>Lista Clientes</h3>
                </div>
            </div>



        </div>

        <!--  fin   -->
        <div class="fechadv">
            <p>{{getDate()}}</p>
        </div>

        <div v-if="identity.role == 'legrafica' || identity.role == 'admin'">

             <div>
              <div  @click="notiVal()"  class="notidv"> <!-- @click="notiChange()" -->
                <img v-if="data.porvalidar == 0 && data.poridentificar==0" src="../assets/Notification.png" alt="">
                <img v-else src="../assets/NotificationOn.png" alt="">
             </div>

                
            </div>
             <p></p>
            <div v-if="data.porvalidar > 0 || data.poridentificar >0">
                      <!--  <div v-if="modalVal" class="triangulo">
                           
                        </div> -->
                    <div v-if="modalVal" class="modalnoti ">
                        <div v-if="data.porvalidar > 0" class="dflx">
                            <p   class="messagenoti">  Hay nuevas muestras por <span class="toval" @click="toVal()" >validar.</span>       </p>  <p @click="closenoti()" class="closenoti"> &#215;</p>    
                        </div>
                        <hr>
                        <div v-if="data.poridentificar >0" class="dflx">
                           <p  class="messagenoti">  Hay nuevas muestras por <span class="toval" @click="toMtr()" >identificar.</span>       </p>  <p @click="closenoti()" class="closenoti"> &#215;</p>    

                        </div>
                  
                   </div>
              
              
              
            </div>
            
            <div v-else>
               <!--  <div v-if="modalVal" class="triangulo">
                           
                        </div>-->
                <div v-if="modalVal" class="modalnoti dflx">
                    <p  class="messagenoti">  Sin novedades.       </p>  <p @click="closenoti()" class="closenoti"> &#215;</p>    
                </div>
            </div>

        </div>
        <div v-else-if=" identity.role == 'spadmin'">

             <div>
              <div  @click="notiVal()"  class="notidv"> <!-- @click="notiChange()" -->
                <img v-if="data.porvalidar2 == 0 && data.poridentificar2==0" src="../assets/Notification.png" alt="">
                <img v-else src="../assets/NotificationOn.png" alt="">
             </div>

                
            </div>
             <p></p>
            <div v-if="data.porvalidar2 > 0 || data.poridentificar2 >0">
                     <!--   <div v-if="modalVal" class="triangulo">
                           
                        </div>-->
                    <div v-if="modalVal" class="modalnoti ">
                        <div v-if="data.porvalidar2 > 0" class="dflx">
                            <p   class="messagenoti">  Hay nuevas muestras por <span class="toval" @click="toVal()" >validar.</span>       </p>  <p @click="closenoti()" class="closenoti"> &#215;</p>    
                        </div>
                        <hr>
                        <div v-if="data.poridentificar2 >0" class="dflx">
                           <p  class="messagenoti">  Hay nuevas muestras por <span class="toval" @click="toMtr()" >identificar.</span>       </p>  <p @click="closenoti()" class="closenoti"> &#215;</p>    

                        </div>
                  
                   </div>
              
              
              
            </div>
            
            <div v-else>
              <!--   <div v-if="modalVal" class="triangulo">
                           
                        </div>-->
                <div v-if="modalVal" class="modalnoti dflx">
                    <p  class="messagenoti">  Sin novedades.       </p>  <p @click="closenoti()" class="closenoti"> &#215;</p>    
                </div>
            </div>

        </div>
         <div v-else-if="identity.role == 'analista'"> <!-- @click="notiChange()" -->
            <div>  
                <div @click="notiVal()" class="notidv">
                    <img v-if="data.poranalizar ==0" src="../assets/Notification.png" alt="">
                    <img v-else src="../assets/NotificationOn.png" alt=""> 
                </div>
            </div>
              <div v-if="data.poranalizar > 0">
                  <!-- <div v-if="modalVal" class="triangulo">
                           
                        </div> -->
                <div v-if="modalVal" class="modalnoti dflx">
                    <p  class="messagenoti">  Hay nuevas muestras por <span class="toval" @click="toMtr()" >analizar/liberar.</span>       </p>  <p @click="closenoti()" class="closenoti"> &#215;</p>    
                </div>
            </div>
            <div v-else>
               <!--  <div v-if="modalVal" class="triangulo">
                           
                        </div> -->
                <div v-if="modalVal" class="modalnoti dflx">
                    <p  class="messagenoti">  Sin novedades.       </p>  <p @click="closenoti()" class="closenoti"> &#215;</p>    
                </div>
            </div>
               
         </div>
         <div v-else class="notidv"> <!-- @click="notiChange()" -->
                <img src="../assets/Notification.png" alt="">
              <!--  <img  src="../assets/NotificationOn.png" alt=""> -->
         </div>

        

      

     


         <div class="menu_user" @click="ActiveMenu()">
               <div  class="icon_user">
                    {{identity.name | split}}
               </div>
               <div class="arrow_menu">
                   <div v-if="showMenu">
                       <img  src="../assets/Vector 4.png" alt="">
                   </div>
                   <div v-else>
                        <img  src="../assets/Vector 3.png" alt="">
                   </div>

               </div>
        </div>

        <div v-if="showMenu" class="modalMenuUser">
           <div class="modal_sec1">
               <h3>{{identity.fullname }}</h3> <!-- | splitUp  | splitName -->
               <p>{{identity.role }}</p>
           </div>
           <hr>
           <div class="modal_sec2">
              <div @click=" saveLab('barragan')" class="group_modal_1" v-if="identity.permisos==='clinicos' || identity.permisos === 'todos'">
                    <div v-if="select_lab=='barragan'" class="active_i"><span>&#9679;</span></div> 
                    <div v-else class="inact_i"><span> &#9679;</span></div>
                    <input v-model="labo" type="radio" name="laboLB" id="laboLB"  value='Lab. Barragán'> 
                 
                    <label :class="{activeLabel: labo == 'Lab. Barragán'}" for="laboLB">Lab. Barragán</label>
              </div>
              <div  @click=" saveLab('laason')" class="group_modal_2" v-if="identity.permisos==='alimentos' || identity.permisos === 'todos'">
                    <div  v-if="select_lab=='laason'" class="active_i"><span> &#9679;</span></div> 
                    <div v-else class="inact_i"><span> &#9679;</span></div>
                    <input v-model="labo" type="radio" name="laboLA" id="laboLA"  value='Lasson'> <label :class="{activeLabel: labo == 'Lasson'}" for="laboLA">Lasson</label>
              </div>
           </div>
           <hr>
           <div class="modal_sec3">
               <h3>Perfil</h3>
               <p class="cerrar_sesion" @click="cerrarSesion()">Cerrar Sesión</p>
           </div>
        </div>
</div>
       
    
</template>
<script>
import dayjs from 'dayjs';
import { es } from "dayjs/locale/es";
import {mapActions} from 'vuex';
export default {
  name: 'HeaderSec',
   data(){
       return{
           search_value:'',
           status:'',
           message:'',
           showMenu:false,
           labo:'Lasson',
           noti:false,
           validados:0,
           activas:0,
           porvalidar:0,
           modalVal:false,
           poranalizar:0,
           poridentificar:0,

           validados2:0,
           activas2:0,
           porvalidar2:0,
           poranalizar2:0,
           poridentificar2:0,
       }
   },
   created(){
    this.getIdentity()

    let day1 =  localStorage.getItem('fecha1')
    let day2 =  localStorage.getItem('fecha2')

    if(!day1 || !day2){
        day1 =  dayjs().format('YYYY-MM-DD')
        day2 =  dayjs().subtract(7,'days').format('YYYY-MM-DD')

        localStorage.setItem('fecha1',day1)
        localStorage.setItem('fecha2',day2)
    

    }



      let payload ={
        option:'muestras_rg',
        data:{
            fecha1:day1,
             fecha2:day2
        }
    }
    this.getAllRangeMtr(payload)
   
},
  computed:{
    identity() {
      return this.$store.getters['admin/getIdentity'];
    },
     historyOption(){
            return this.$store.getters["main/getHistoryOption"]
        },
        select_lab(){
            return this.$store.getters["main/getlab"]
        },
        historyOptionSrv(){
            return this.$store.getters["solserv/getHistoryOption"]
        },


          historyOptionMtr(){
            return this.$store.getters["muestras/getHistoryOption"]
        },
         historyOptionVal(){
            return this.$store.getters["validaciones/getHistoryOption"]
        },


         data() {
           let data = this.$store.getters["muestras/data"]
          
           let count =0
           let count2 =0
           let count3 =0
           let count4 =0
        
        //forma 2
           let count5 =0
           let count6 =0
           let count7 =0
           let count8 =0
           let act =0
            let act2 =0
          
          
           if(data !='error' && data !='No se encontraron coincidencias.'){
               for(var i=0; i<data.length; i++){
                   if(data[i].servicio.forma =='1'){
                        if(data[i].superviso != null && data[i].superviso != '' ){
                            count= count+1
                        }

                    
                        if( (data[i].superviso == '' || data[i].superviso == null ) && data[i].f_liberacion != null && data[i].f_liberacion != '' ){
                            count2= count2+1
                        }

                        if( (data[i].toma != null && data[i].toma != '' && data[i].f_liberacion == null)){
                            count3= count3+1
                        }

                        if( data[i].toma == null || data[i].toma == '' ){
                            count4= count4+1
                        }

                        act++
                   }else{
                         if(data[i].superviso != null && data[i].superviso != '' ){
                            count5++
                        }

                    
                        if( (data[i].superviso == '' || data[i].superviso == null ) && data[i].f_liberacion != null && data[i].f_liberacion != '' ){
                            count6++
                        }

                        if( (data[i].toma != null && data[i].toma != '' && data[i].f_liberacion == null)){
                            count7++
                        }

                        if( data[i].toma == null || data[i].toma == '' ){
                            count8++
                        }

                         act2++
                   }
              }
                this.validados=count
                this.activas = act;
                this.porvalidar = count2;
                this.poranalizar = count3
                this.poridentificar = count4
                

                 this.validados2=count5
                this.activas2 = act2;
                this.porvalidar2 = count6;
                this.poranalizar2 = count7
                this.poridentificar2 = count8
            }
           
        
             return {validados: this.validados, activas: this.activas, porvalidar: this.porvalidar, poranalizar:this.poranalizar, poridentificar:this.poridentificar,
             validados2: this.validados2, activas2: this.activas2, porvalidar2: this.porvalidar2, poranalizar2:this.poranalizar2, poridentificar2:this.poridentificar2
                        
                        };
        },
  },
  methods:{
             ...mapActions('muestras', ['getAllInfoMtr']),
             ...mapActions('muestras', ['getAllRangeMtr']),
              ...mapActions('main', ['setHistoryOptionMain']),
               ...mapActions('main', ['setlab']),
      getIdentity: async function (){
          let response =  await this.$store.dispatch("admin/getData");
          return response
      },
   saveLab(value){
        localStorage.setItem('laboratorio', value)
        this.wait()
    },
        wait: function () {
        setTimeout(() => this.$router.go(), 200);
        },
    
      ActiveMenu: async function(){
          if(this.showMenu){
              this.showMenu = false
          }else{
            this.showMenu = true;
          }
         
      },
      cerrarSesion: async function(){
           let response =  await this.$store.dispatch("admin/logout");
           this.wait()
      },

      toVal(){
           this.setHistoryOptionMain('Validaciones')
      },
       toMtr(){
           this.setHistoryOptionMain('Muestras')
      },
      notiVal(){
          if(this.modalVal == false){
              this.modalVal = true
              this.closem()
          }else{
              this.modalVal = false
          }
          
      },
      closenoti(){
           this.modalVal = false
      },
    closem:function(){
       setTimeout(() => this.closenoti(), 10000);
    },


        notiChange(){
            if(this.noti){
                this.noti = false;
            }else{
                this.noti = true;
            }
            
        },

      getDate:function(){
          let date;
          dayjs.locale("es");
          let day = dayjs().format('DD');
          let mes = dayjs().format('MMMM');
          let ano = dayjs().format('YYYY')

          let splitStr = mes.toLowerCase().split(' ');
           
            
          
          let mesct = splitStr[0].charAt(0).toUpperCase() + splitStr[0].substring(1);     
         

          date = day + ' de '+ mesct + ' del ' + ano;
          return date
      },

      getDate2:function(){
          let date;
          dayjs.locale("es");
       
          let mes = dayjs().format('MMMM');
          let ano = dayjs().format('YYYY')

          let splitStr = mes.toLowerCase().split(' ');
           
            
          
          let mesct = splitStr[0].charAt(0).toUpperCase() + splitStr[0].substring(1);     
         

          date =  mesct + ' ' + ano;
          return date
      }
 
    },
    filters:{
     split: (value) => {
            if (!value) return '';
            value = value.toString();

          
            return  value.charAt(0).toUpperCase() 
          

        },
        splitUp: (value) => {
            if (!value) return '';
            value = value.toString();

            let splitStr = value.toLowerCase().split(' ');
           

           
             return  splitStr[0].charAt(0).toUpperCase() + splitStr[0].substring(1);     

        },
        splitName: (value) => {
            
            if (!value) return '';
            value = value.toString();
            

            let splitStr = value.toLowerCase().split(' ');
            let len = splitStr.length
            
            if(len == 1){
              return  splitStr[0].charAt(0).toUpperCase() + splitStr[0].substring(1);     
            }

            return  splitStr[0].charAt(0).toUpperCase() + splitStr[0].substring(1) + ' ' + splitStr[1].charAt(0).toUpperCase() + splitStr[1].substring(1);  ;  

          

        },

        
 }
}
</script>

<style scoped>

.modalnoti{
    position: absolute;
    top: 15%;
    right: 12%;
    background: #E5E5E5;
    padding: .5vw;
    width: 20vw;
    height: auto;
    overflow: auto;
}

.modalnoti p{
    margin-bottom: 0px;
    color: #868686;

}
.modalnoti p span.toval{
    cursor: pointer;
    font-weight: 700;
}

.modalnoti hr{
    margin-top: .5vw;
    margin-bottom: .5vw;
}

.triangulo {
        width: 0;
    height: 0;
    border-right: 1vw solid transparent;
    border-top: 1vw solid transparent;
    border-left: 1vw solid transparent;
    border-bottom: 1vw solid #e5e5e5;
    position: absolute;
    top: 4.7vw;
    right: 13.1vw;
}

.closenoti{
    font-weight: 700;
  
}
p.closenoti{
     width:10%;
     text-align: center;
     cursor: pointer;
}
p.messagenoti{
    width: 90%;
}


    #HeaderSec{
        display: flex;
    }

.options{
    width: 30vw;
    display: flex;
    margin-left: 4.583333333333333VW;
}.div_option{
    display: flex;
   
}.arrow_left{
    padding-top: 3.715104166666667VW;
    width: 1.953125VW;
    height: 1.09375VW;
}.io_user{
    padding-top: 3.177083333333333VW;
    margin-left: 1.3802083333333333VW;
    width: 2.34375VW;
    height: 2.34375VW;
}.io_user img{
  
    width: 2.34375VW;
    height: 2.34375VW;
}.io_dash{
    padding-top: 3.177083333333333VW;
    margin-left: 1.3802083333333333VW;
    width: 2.34375VW;
    height: 2.34375VW;
}.io_dash img{
    width: 2.34375VW;
    height: 2.34375VW;
}.io_val{
    padding-top: 3.177083333333333VW;
    margin-left: 1.3802083333333333VW;
    width: 2.34375VW;
    height: 2.34375VW;
}.io_val img{
    width: 1.875VW;
    height: 2.2916666666666665VW;
}.text_option h3{
    margin-left: 0.9375VW ;
    margin-top: 0vw;
    padding-top: 3.4375000000000004VW;
    font-size: 1.8229166666666667VW;
    color: var(--color-4);
    font-weight: 300 !important;
    font-style: normal;
}
.fechadv{
    margin-left: 15vw;
    width: 12.791666666666666VW;
    margin-top: 3.7vw;
}
.fechadv p{
    font-size: 0.8333333333333334VW;
    font-family: var(--Sans);
    color: #868686;
    font-weight: 300;
}
.notidv{
    margin-top: 3.5vw;
}
.notidv img{
    width: 1.5104166666666667VW;
    
}

.buscador {
    display: flex;
    margin-top: 3.777604166666667VW;
    margin-left: 25.93749999999999VW;
}
   .buscador input{
     border-radius: 0vw;
     border: 0.052083333333333336VW solid var(--color-6);
     width: 11.250000000000002VW;
     height: 1.8192708333333332VW; 
     padding-left: 1.8vw;
     font-size: 0.6770833333333334VW;
     color: var(--color-4) !important;
     border-right: 0vw;
   }.buscador input:target{
       background: var(--color-1);
   }

   input:-internal-autofill-selected {
       background: white !important;
   }

   .icon_buscar{
        border-radius: 0vw;
        border: 0.052083333333333336VW solid var(--color-6);
        width: 2.5VW;
        height: 1.8192708333333332VW;
        padding-left: 0.8vw;
        font-size: 0.6770833333333334VW;
        color: var(--color-4) !important;
    border-left: 0vw;
   }.icon_buscar img{
       width: 0.9895833333333333VW;
       height: 0.9895833333333333VW;
       margin-top: 0.4411458333333334VW;
   }

  
  .menu_user{
      display: flex;
      margin-left: 2.1875VW;
      padding-top: 2.9427083333333335VW;
  }.icon_user{
      background: var(--color-2);
      padding-top: 0VW;
      padding-left: .8vw;
      height: 3.0729166666666665VW;
      width: 3.0729166666666665VW;
      color: var(--color-5);
      font-weight: 700;
      font-size: 2vw;
      border-radius: 2vw;
  }.arrow_menu{
      margin-left: 0.625VW;
      padding-top: 1vw;
      width: 1.0416666666666665VW;
      height: 0.4166666666666667VW;
  }

  .modalMenuUser{
      position: absolute;
      background: var(--color-5);

      right: 0vw;
      top: 7vw;
      width: 15VW;
  }.modalMenuUser hr{
      width: 10.885416666666666VW;
        border-top: 0.052083333333333336VW solid var(--color-6);
      
  }.modal_sec1, .modal_sec3{
     text-align: center;
  }.modal_sec1 h3{
      font-size: 1.0416666666666665VW;
      font-weight: 700;
      color: var(--color-4);
  }.modal_sec1 p{
      font-weight: 400;
      font-size: 0.7291666666666666VW;
       color: var(--color-4);
       margin-bottom: 1.09375VW;
  }.modal_sec3 h3{
      font-size: 1.0416666666666665VW;
      font-weight: 700;
      color: var(--color-3);
      margin-bottom: 0.8333333333333334VW;
  }.modal_sec3 p{
      font-weight: 700;
      font-size: 0.8333333333333334VW;

       color: var(--color-4);
       margin-bottom: 1.09375VW;
  }

  .modal_sec2{
      margin-left: 3.3854166666666665VW;
  }.modal_sec2 label{
    margin-left: 0.9375VW;
    font-size: 1.0416666666666665VW;
    font-weight: 400;
    padding-top: 0.78vw;
  }

  label.activeLabel{
    font-weight: 700;
  }
  
    input[type="radio"] {
       display: none;
    }
    
    .group_modal_1, .group_modal_2{
        display: flex;
    }
    

    .active_i span{
        color: var(--color-3);
        font-size: 2vw;
    }

    .inact_i span{
        color: var(--color-5);
        font-size: 2vw;
    }   


    .modal_sec3 p:hover{
        color:var(--color-2);
    }
    
   



</style>
<template>
  
    <div id="muestrasClientes">
  
        <div class="info_cliente">
            <p class="client_name">  <span v-if="muestra.servicio.id_sucursal == null">{{muestra.servicio.cliente.name}} </span>  <span v-else>{{muestra.servicio.sucursal.name}} </span> </p>
             <p class="t1p">{{muestra.servicio.clave_LAASON}}</p>
             <p class="t2p">Datos de la muestra</p>

            <div class="dflx">
            <p class="lbl1">Tipo de Muestra</p> 
            <p class="inp i1">{{muestra.tipo}}</p>   
            <p class="lbl2">Muestreada por</p>
            <p class="inp i2"> {{muestra.toma}}</p>  
            <p class="lbl3">Lugar de muestreo</p>
            <p class="inp i3">{{muestra.toma}}</p>  
         </div>
        
           <div class="dflx">
            <p class="lbl4">Descripción</p> 
            <p class="inp i4">{{muestra.descrp}}</p>   
             
         </div>

        <div class="dflx">
            <p class="lbl5">Fecha de impresion</p> 
            <p class="inp i5">{{convertdate(muestra.servicio.fecha)}} {{muestra.servicio.hr}} hrs</p>   
            
         </div>


         <p class="t2p tt2">Resultados de la muestra</p>
        <div class="dflx">
            <p class="lbl6">Fecha de inicio</p> 
            <p class="inp i6"> <span v-if="muestra.fecha_i != null"> {{convertdate(muestra.fecha_i)}}</span> </p>   
             <p class="lbl7">Fecha de finalización</p> 
            <p class="inp i7"><span v-if="muestra.fecha_f != null"> {{convertdate(muestra.fecha_f)}}</span></p>   
            
         </div>

           <div class="dflx">
            <p class="lbl8">Naturaleza</p> 
            <p class="inp i8">{{muestra.tipo}}</p>   
           
            
         </div>
         
         
        </div> <!-- info cliente --->

   

        <div>
           
        

        <div class="tbl_muestracliente">
            <div class="tbl_header">
                <div class="tblh hdr1">
                  <p>DETERMINACIONES</p>
                </div>
                 <div class="tblh hdr2">
                  <p>RESULTADOS</p>
                </div>
                 <div class="tblh hdr3">
                  <p>LIMITE DE CUANTIFICACIÓN</p>
                </div>
                 <div class="tblh hdr4">
                  <p>REFERENCIA</p>
                </div>
                
               
               
            </div>
           
            <div tbl_content>
                <div > 
                    <div class="tbl_items" v-for="(res, key) in muestra.resultados" :key="key">
                        <div  class="tbl_item itt1">
                               <p>{{res.pname}} </p>
                        </div>
                         <div  class="tbl_item itt2">
                               <p> <span v-if="res.resultado != null">{{res.resultado + ' ' +res.uni}}</span> </p>
                        </div>
                         <div  class="tbl_item itt3">
                               <p>{{res.lc}}</p>
                        </div>
                         <div  class="tbl_item itt4">
                               <p>{{res.ref}}</p>
                        </div>
                       
                       
                       
                    </div>
                    
                </div>  
          
            </div>
        </div>

        </div>

        <!--
        <p class="t2p tt2">Analistas</p>
        <div>
           
        

        <div class="tbl_analistas">
            <div class="tbl_header">
                <div class="tblh hdr5">
                  <p>NOMBRE</p>
                </div>
                 <div class="tblh hdr6">
                  <p>SIGLAS</p>
                </div>
                 <div class="tblh hdr7">
                  <p>ESTATUS</p>
                </div>
                
                
               
               
            </div>
            
            <div tbl_content>
              
                <div > 
                    <div class="tbl_items" >
                        <div  class="tbl_item itt5">
                               <p>{{muestra.analistas.analista.nombre}}</p>
                        </div>
                         <div  class="tbl_item itt6">
                               <p> {{muestra.analistas.analista.firma}}</p>
                        </div>
                         <div  class="tbl_item itt7">
                               <p>ANALISTA</p>
                        </div>
                    </div>
                </div>  

                 <div > 
                    <div class="tbl_items" >
                        <div  class="tbl_item itt5">
                               <p>{{muestra.analistas.liberacion.nombre}}</p>
                        </div>
                         <div  class="tbl_item itt6">
                               <p> {{muestra.analistas.liberacion.firma}}</p>
                        </div>
                         <div  class="tbl_item itt7">
                               <p>LIBERACIÓN</p>
                        </div>
                    </div>
                </div>  


                 <div > 
                    <div class="tbl_items" >
                        <div  class="tbl_item itt5">
                               <p>{{muestra.analistas.superviso.nombre}}</p>
                        </div>
                         <div  class="tbl_item itt6">
                              <p> {{muestra.analistas.superviso.firma}}</p>
                        </div>
                         <div  class="tbl_item itt7">
                               <p>SUPERVISÓ</p>
                        </div>
                    </div>
                </div>  


          
            </div>
        </div>

        </div>
        -->
                        
                        
                        
                        
                        
                        
                        
                        
                        
                        <div class="dflx">
                             <p @click="back()" class="btnRegresar3">Regresar</p>
                             <p @click="PDFview(muestra)" class="btnsFoo btf1"> Descargar PDF</p>
                             <p @click="PDFview(muestra)" class="btnsFoo">Reenviar PDF</p>
                             
                        </div>

      
     

       <div v-if="status !=''" class="status_messages">
                            <div v-if="status =='success'" class="msg msg_success">
                                 <p>{{message}}</p>
                            </div>
                            <div  v-if="status =='error'" class="msg msg_error">
                                <p>{{message}}</p>
                            </div>
        </div>
        
        
        

    </div>
</template>
<script>

import dayjs from 'dayjs'
import {mapActions} from 'vuex';
export default {
    name: 'muestrasClientes',
    
 
   data(){
       return{

           status:'',
           message:'',
           item: '',
           variable:'< 1.1 NMP/100 ml'
       }
   },
   created(){
      
   },

    computed:{
        cliente(){
            return this.$store.getters["clientes/getAdded"]
        },
        historyOption(){
            return this.$store.getters["clientes/getHistoryOption"]
        },

        muestra(){
            return this.$store.getters["muestras/getAdded"]
        },


      
    },
   
   methods:{
         ...mapActions('clientes', ['setHistoryOptionCli']),
         ...mapActions('clientes', ['setAddedCli']),
         ...mapActions('PDF', ['setAddedPDF']),
      ...mapActions('main', ['setHistoryOptionMain']),

      PDFview: function(muestra) {
          if(muestra.superviso =="" || muestra.superviso==null){
              this.message='Esta muestra aún no se ha supervisado'
              this.status='error'
               this.delStatus2()
          }else if(muestra.extra.apar == '' || muestra.extra.apar == null){
               this.message='Faltan campos por llenar. (apariencia, cantidad, presentación, transporte).'
               this.status='error'
               this.delStatus2()
          }else{
            this.setAddedPDF(muestra)
            if(muestra.extra.pdfidioma === 'ingles')this.setHistoryOptionMain('PDFenglishcreate')
            else this.setHistoryOptionMain('PDFcreate')
          }
      },


        wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },
        delStatus: function () {
            setTimeout(() => this.delMsgs()
            ,2000);
            setTimeout(() => this.wait()
            ,500);
            
        },
          delStatus2: function () {
            setTimeout(() => this.delMsgs()
            ,2000);
          
            
        },
        delMsgs: function(){
            this.status=""
            this.message=""
        },
        back(){
        this.setHistoryOptionCli('VistaCliente')
        },
      
             convertdate(fecha){
            let day = dayjs(fecha).format('DD/MM/YYYY')
            return day
        },
        determinaciones(resultados){
            let str =''
            if(resultados ==''){
                return str
            }
            for(var i=0; i< resultados.length; i++){
                if(i == 0){
                    str = str + resultados[i].pabr
                }else{
                    str = str + ',' + resultados[i].pabr
                }
            }

            return str
        },

        submit: async function ( image ) {
          this.status=''
          this.msg=''

         if(image == null){
            this.status='error'
            this.msg='Imagen no subida'
             this.delStatus2()
        }else{
                var data = new  FormData();
              
                  data.append('image', image);
                  data.append('_method', 'POST');
                
                      
                  this.status=''
                  this.msg=''
              
                  let result = await this.$store.dispatch("main/addItem",  {option:'send', item: data});
                
                  if(result.status=='error'){
                    this.status='error'
                    this.msg= result.message
                     this.delStatus()
                  }else{ // success
                      this.content=''
                      this.added= result.added
                      this.showModal() 
                  }

        }

    },
       
   }

    
 
}
</script>

<style scoped>
  hr.hrinf{
    width: 57.969vw; 
    border: 0.5px solid #D3D3D3;
    margin-left: -1vw;
    margin-bottom: 2vw;
    margin-top: 1vw;
  }


    #muestrasClientes{
        margin-top: 5.288020833333333VW;
        margin-left: 0.9375VW ;
        }

        .info_cliente p{
            font-size: 0.677vw;
            padding-top: 0.148vw ;
            color: #868686;
        }

        p.client_name{
            font-size: 1.615vw;
            font-weight: 400;
            padding-top: 0vw;
        }

        p.t1p{
            font-weight: 300;
            font-style: normal;
            font-size: 1.615vw;
            color: #565656;
        }

        p.t2p{
            font-size: 1.042vw;
             font-weight: 300;
            font-style: normal;
            color: #565656;
        }

        p.tt2{
            margin-top: 3.646vw;
        }








        /** inputs */

   p.inp{
        border: 0.052083333333333336VW solid #D3D3D3 ;
       color: #868686;
       font-weight: 700;
       height: 1.354vw;
       padding-left: 0.573vw;
   }

    p.i1{
        width: 10.993vw;
         font-weight: 700;
    }
    p.lbl1{
         font-weight: 400;
        width: 5.573vw;
        margin-right: 0.469vw;
        font-size: 0.729vw;
    }

    p.i2{
        width: 10.773vw;
        font-weight: 700;
    }
    p.lbl2{
         font-weight: 400;
         font-size: 0.729vw;
        width: 5.469vw;
        margin-right: 0.313vw;
        margin-left: 1.094vw;
    }

    p.i3{
        width: 12.388vw;
         font-weight: 700;
    }
    p.lbl3{
         font-size: 0.729vw;
         font-weight: 400;
        width: 6.458vw;
        margin-right: 0.417vw;
        margin-left: 0.885vw;
    }

      p.i4{
        width: 50.8vw;
         font-weight: 700;
    }
    p.lbl4{
         font-weight: 400;
         font-size: 0.729vw;
        width: 4.010vw;
        margin-right: 0.1vw;
    }

    p.i5{
        width: 9.931vw;
         font-weight: 700;
    }
    p.lbl5{
         font-weight: 400;
         font-size: 0.729vw;
        width:  6.667vw;
        margin-right: 0.417vw;
    }

     p.i6{
        width: 9.931vw;
         font-weight: 700;
    }
    p.lbl6{
         font-weight: 400;
         font-size: 0.729vw;
        width:  5.052vw;
        margin-right: 2.031vw;
    }

    p.i7{
        width: 9.931vw;
         font-weight: 700;
    }
    p.lbl7{
         font-weight: 400;
         font-size: 0.729vw;
        width:  7.02vw;
        margin-left: 0.679vw;
    }

      p.i8{
        width: 9.931vw;
         font-weight: 700;
    }
    p.lbl8{
         font-weight: 400;
         font-size: 0.729vw;
        width:  3.750vw;
        margin-right: 3.333vw;
    }
  



   .btnsFoo{
        cursor:pointer;
        width: 10.156vw;
        height: 2.292vw;
        border: 0px;
        margin-right: 0.072vw;
        font-size: 0.729vw;
        font-weight: 700;
        color:white;
        text-align: center;
        padding-top: .7vw;
        background: #007E94;
        margin-top: 2.7083333333333335VW;
    }
    .btf1{
         margin-left: 0.072vw;
    }
  


 



    /** TABLA */

    .tbl_muestracliente{
        width: 55VW; 
        margin-top: 1.5625VW;
    }

    .tbl_analistas{
        width: 45VW; 
        margin-top: 1.5625VW;
    }

    .tbl_header{
        display: flex;
        background:var(--color-2);
    }.tblh, .tbl_item{
        height: 2.2916666666666665VW;
    }.tblh p{
        color:var(--color-5);
        font-weight: 700;
        font-size: 0.625vw;
        padding-top: 0.66875VW;
        /*padding-left: 1.4583333333333333VW;*/
        text-align: center;
    }

    .tbl_items{
        display: flex;
        
    }.tbl_item{
         padding-top: 0.36875VW;
         padding-left: .9VW;
         background: #F8F8F8;
         border: 1px solid white;

    }.tbl_item p{
        font-size:0.7291666666666666VW;
     
    }




    /* TABLA */

    .hdr1{
        width:18.229vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }
   .itt1{
        width: 18.229vw;
         overflow: auto;
    }

    .hdr2{
        width:15.573vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }
   .itt2{
        width: 15.573vw;
         overflow: auto;
    }

    .hdr3{
        width:12.969vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }
   .itt3{
        width:12.969vw;
         overflow: auto;
    }

    .hdr4{
        width:8.229vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }
   .itt4{
        width:8.229vw;
         overflow: auto;
    }

    .hdr5{
        width:26.510vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }
   .itt5{
        width:26.510vw;
         overflow: auto;
    }

      .hdr6{
        width:5.521vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }
   .itt6{
        width:5.521vw;
         overflow: auto;
    }

       .hdr7{
        width:12.969vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }
   .itt7{
        width:12.969vw;
         overflow: auto;
    }


    

     

    

  /** estilos scroll bar */
    .tbl_item::-webkit-scrollbar {
    -webkit-appearance: none;
    }

    .tbl_item::-webkit-scrollbar:vertical {
        width:0.3645833333333333VW;
    }

    .tbl_item::-webkit-scrollbar-button:increment,.tbl_item::-webkit-scrollbar-button {
        display: none;
    } 

    .tbl_item::-webkit-scrollbar:horizontal {
        height: 0.3645833333333333VW;
    }

    .tbl_item::-webkit-scrollbar-thumb {
        background-color: #797979;
        /*border-radius: 1.0416666666666665VW;*/
        border: 0.10416666666666667VW solid #f1f2f3;
    }

    .tbl_item::-webkit-scrollbar-track {
        border-radius: 0.3645833333333333VW;  
    }

</style>
